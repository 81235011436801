<template>
    <section class="intellectual-property py-5 d-flex flex-column flex-lg-row">
        <div class="d-flex justify-content-center col-12 col-lg-6">
            <img
                class="intellectual-property__img"
                src="../../assets/views/project_expertise/legal-img.svg"
                alt=""
            >
        </div>

        <div class="intellectual-property-block col-12 col-lg-6 ms-2 ms-lg-0 mt-4 mt-lg-0 align-self-center ps-2">
            <div class="intellectual-property__title">{{ getLegalPageInfo('title') }}</div>
            <div class="intellectual-property__text mt-4">
                {{ getLegalPageInfo('text') }}
            </div>

            <ul class="p-0 mt-3">
                <li
                    v-for="(point, index) in getPoints()"
                    :key="index"
                    class="d-flex align-items-center mt-3"
                >
                    <img src="../../assets/views/technopark/check.svg" alt="">
                    <div
                        class="ms-3 accelerator-programs__text"
                        v-html="point"
                    >
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";

export default {
    name: "Legal",

    setup() {
        const store = useStore()

        store.dispatch('pages/getProjectsExpertisePage')
        const projectsExpertisePage = computed(() => store.getters['pages/projectsExpertisePage'])

        const getPoints = () => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.fourth_tab.points_split
            }

            return []
        }

        const getLegalPageInfo = (key) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.fourth_tab[key]
            }

            return ''
        }

        return {
            getPoints,
            getLegalPageInfo
        }
    }
}
</script>
